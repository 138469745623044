// Note this file should be loaded after the <xsl:call-template name="tdi-js"/>; default NOT loaded.

$(".lang-EN").click( function(e) {
	if (
	document.getElementById("language-text").innerText.indexOf("NL") !== -1) {
	document.getElementById("language-text").innerHTML = "EN";
	languageSwitch("EN");
	};
	$("#languageMenu").find('.fas').toggleClass('fas fa-chevron-up fas fa-chevron-down');
});

$(".lang-NL").click( function(e) {
	if (
  document.getElementById("language-text").innerText.indexOf("EN") !== -1) {
	document.getElementById("language-text").innerHTML = "NL";
	languageSwitch("NL");
	};
	$("#languageMenu").find('.fas').toggleClass('fas fa-chevron-up fas fa-chevron-down');
});

$("#languageMenu").on( "click", function(e){
  $(this).toggleClass("open");
});

function languageSwitch(language){
  if (language == "EN") {
    $("body").find('.EN').removeClass("hide-div");  
    $("body").find('.NL').addClass("hide-div");  
  } else {
    $("body").find('.NL').removeClass("hide-div");
    $("body").find('.EN').addClass("hide-div");
  }
};

tooltips.init(); // enables tooltip support for footnotes and termrefs